.assessment-container {
  max-width: 95%;
  margin: 0 auto;
  margin-left: 3vw;

  .results-container {
    display: flex;
    gap: 30px;
    margin-top: 40px;
  }
}

.modal-last {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 40px 30px;
  position: relative;
  text-align: center;
  outline: none;
  border: none;
  .title {
    margin: 0;
    padding: 0;
    color: #212121;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .title2 {
    color: #4f4f4f;
    font-family: "Poppins";
    margin-top: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .nextButton {
    border-radius: 8px;
    background: #062b6e;
    color: #fff;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 15px 24px;
    margin-top: 35px;
    &:hover {
      background: #062b6e;
    }
  }
  .cancel {
    border-radius: 8px;
    border: 1px solid #777;
    background: #fff;
    color: #777;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 15px 24px;
    margin-top: 35px;
    margin-right: 36px;
  }
}

.modal {
  max-width: 550px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 40px 30px;
  position: relative;
  .title {
    margin: 0;
    padding: 0;
    color: #212121;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .title2 {
    color: #4f4f4f;
    font-family: "Poppins";
    margin-top: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .close-button {
    right: 15px;
    top: 15px;
    position: absolute;
  }
  .radio-group {
    margin-top: 32px;
    display: flex;
    gap: 30px;
  }
  .availability {
    margin-top: 38px;

    & > label {
      color: #212121;
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 10px;
    }
    .availability-date {
      margin-top: 10px;
      input {
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 8px;
        border: 1px solid #e0e0e0;
        background: #f8f9fb;
      }
    }
  }
  .project-selection {
    margin-top: 36px;
    & > label {
      color: #212121;
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .project-input {
      margin-top: 10px;
      input,
      div {
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 8px;
        border: 1px solid #e0e0e0;
        background: #f8f9fb;
      }
      div {
        border: none;
      }
    }
  }
  .view-project {
    color: #062b6e;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
    display: block;
    margin-top: 14px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .nextButton {
    border-radius: 8px;
    background: #062b6e;
    color: #fff;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 15px 24px;
    margin-top: 35px;
    &:hover {
      background: #062b6e;
    }
  }
  .cancel {
    border-radius: 8px;
    border: 1px solid #777;
    background: #fff;
    color: #777;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 15px 24px;
    margin-top: 35px;
    margin-right: 36px;
  }
}
