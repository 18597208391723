.itemList {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #000000;
  flex: 1;

  border-radius: 8px;
  background: #fff;
  box-shadow: -2px -2px 4px 0px rgba(79, 79, 79, 0.12),
    2px 2px 4px 0px rgba(79, 79, 79, 0.2);
  margin-bottom: 50px;

  .round4 {
    padding: 18px 30px;
    color: #212121;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */

    background: #fff;
    filter: drop-shadow(2px 2px 4px rgba(79, 79, 79, 0.12))
      drop-shadow(-2px -2px 4px rgba(79, 79, 79, 0.1));
  }

  .marginTop {
    margin-top: 28px;
  }
  .eachItem {
    border-bottom: 1px solid #d5d5d5;
    padding: 27px 24px;

    &:last-child {
      border-bottom: none;
    }

    .itemTitle {
      display: flex;
      align-items: center;
      color: #333;
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 133.333% */
      border-bottom: 1px solid #d5d5d5;
      padding-bottom: 20px;

      .arrow {
        margin-right: 8px;
        transition: transform 0.3s;
        margin-top: 4px;
        color: #0b52d4;

        &.open {
          transform: rotate(90deg);
          margin-top: 0px;
        }
      }
    }

    .itemRating {
      margin-left: auto;
    }
    .desc {
      color: #333;
      font-family: "Inter";
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      padding-top: 20px;
      & > div {
        margin-bottom: 10px;
      }
      .eachItemInput {
        & > textarea {
          width: 100%;
          border: 1px solid #d4d5d9;
          border-radius: 8px;
          outline: none;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          margin-top: 18px;
          color: #212121;
          padding: 16px;
          outline: none;
          resize: none;
          height: 90px;
        }
      }
    }
  }
  .totalScore {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 30px;
    align-items: center;
    background: #ccdeff;
    & > div:nth-child(2) {
      font-family: "Poppins";
      display: flex;
      align-items: center;
      color: #062b6e;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      gap: 6px;
    }
    .totalValue {
      & > div:nth-child(1) {
        color: #212121;
        font-family: "Poppins";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      & > div:nth-child(2) {
        color: #212121;
        font-family: "Poppins";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
